import React from "react"
import { Col } from "reactstrap"
import Footer from "./Footer"

import "./styles.css"

const toyFairBotLogo = require("../../../images/toyfair-logo.png")
const brandLogo = require("../../../images/brand-logo.png")

const SideNav = () => (
  <Col className="px-0 nav-col" sm="12" md="5">
    <div className="nav-container d-flex flex-column w-100 h-100">
      <div className="nav-brand-container  p-2 d-flex align-items-center">
        <img alt="" className="brand-logo" src={brandLogo} />
        <div className="d-flex flex-column logo-title-container">
          <div className="logo-title">Pickle</div>
          <div className="logo-subtitle">The Toy Fair Bot</div>
        </div>
      </div>
      <div className="flex-grow-1 d-flex flex-column tf-logo-container justify-content-center align-items-center">
        <img className="tf-logo" width="220" alt="" src={toyFairBotLogo} />
      </div>
      <Footer />
    </div>
  </Col>
)

export default SideNav
