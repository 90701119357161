import React from "react"
import { FaAngleRight } from "react-icons/fa"
import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap"

const onPostMethod = (emailAddress) => {
  const url = "https://toyfairbot-node-dev.four13.co/save_email/"
  const data = { email_address: emailAddress }
  const inputClear = document.getElementById("inputID")

  inputClear.value = ""

  fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
    .then((result) => {
      console.log("Success", result)
    })
    .catch((error) => {
      console.error(error)
    })
}

const onClickSubmit = () => {
  const emailAddress = document.getElementById("inputID").value
  onPostMethod(emailAddress)
}

const steps = [
  {
    id: "1.0",
    message: "Hello There!",
    trigger: "1.1"
  },
  {
    id: "1.1",
    message: "My name is Pickle the Toy Fair Bot!",
    trigger: "1.2"
  },
  {
    id: "1.2",
    message: "What's your name?",
    trigger: "1.3"
  },
  {
    id: "1.3",
    user: true,
    trigger: ({ value }) => {
      if (value === "Rooster") {
        return "1.4"
      }
      return "2.0"
    },
    validator: (value) => {
      if (value) return true
      return "Please enter a valid name."
    }
  },
  {
    id: "1.4",
    message: ({ previousValue }) => `Wait, did you say ${previousValue}?`,
    trigger: "1.5"
  },
  {
    id: "1.5",
    message: `The Rooster told me you might come here.`,
    trigger: "1.6"
  },
  {
    id: "1.6",
    message:
      "That means he's standing right there with you. Since that's the case, give us your email and we'll put you at the front of the list to learn more about me.",
    trigger: "20.16"
  },
  {
    id: "2.0",
    message: "Hey {previousValue}! So nice to meet you!",
    trigger: "2.1"
  },
  {
    id: "2.1",
    message: "Are you in New York City for Toy Fair?",
    trigger: "3.0"
  },
  {
    id: "3.0",
    options: [
      {
        value: "Yes!",
        label: "Yes!",
        trigger: "3.1"
      },
      {
        value: "No",
        label: "No",
        trigger: "3.2"
      }
    ]
  },
  {
    id: "3.1",
    message: "That’s great, me too!",
    trigger: "5.0"
  },
  {
    id: "5.0",
    message: "Do you want to find some games or toys to play?",
    trigger: "5.1"
  },
  {
    id: "3.2",
    message: "That’s too bad.",
    trigger: "3.2.1"
  },
  {
    id: "3.2.1",
    message: "But I’m glad you found me!",
    trigger: "3.2.2"
  },
  {
    id: "3.2.2",
    message: "Do you want to learn about the great toys and games from Toy Fair?",
    trigger: "3.2.3"
  },
  {
    id: "3.2.3",
    options: [
      {
        value: "Yes",
        label: "Yes",
        trigger: "3.2.4"
      },
      {
        value: "No",
        label: "No",
        trigger: "3.3.1"
      }
    ]
  },
  {
    id: "3.2.4",
    message: "Which would you like to learn about?",
    trigger: "3.2.4.1"
  },
  {
    id: "3.2.4.1",
    options: [
      {
        value: "Games",
        label: "Games",
        trigger: "3.2.5"
      },
      {
        value: "Toys",
        label: "Toys",
        trigger: "3.4.1"
      }
    ]
  },
  {
    id: "3.2.5",
    message: "These are my favourite game companies",
    trigger: "3.2.6"
  },
  {
    id: "3.2.6",
    component: (
      <a
        href="https://www.endlessgames.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Endless Games
      </a>
    ),
    // asMessage: true,
    trigger: "3.2.7"
  },
  {
    id: "3.2.7",
    component: (
      <a
        href="https://www.calliopegames.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Calliope Games
      </a>
    ),
    // asMessage: true,
    trigger: "3.2.8"
  },
  {
    id: "3.2.8",
    component: (
      <a
        href="https://www.loadquestions.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        All Things Equal
      </a>
    ),
    // asMessage: true,
    trigger: "3.2.9"
  },
  {
    id: "3.2.9",
    message: "Have a great day!",
    trigger: "20.113",
    delay: 2000
  },
  {
    id: "3.4.1",
    message: "These are my favourite toy companies",
    trigger: "3.4.2"
  },
  {
    id: "3.4.2",
    component: (
      <a href="https://www.funko.com/" target="_blank" rel="noopener noreferrer">
        Funko
      </a>
    ),
    // asMessage: true,
    trigger: "3.4.3"
  },
  {
    id: "3.4.3",
    component: (
      <a
        href="https://www.playvisions.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Playvisions
      </a>
    ),
    // asMessage: true,
    trigger: "3.4.5"
  },
  {
    id: "3.4.5",
    component: (
      <a
        href="https://www.protectgeniusinc.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Protect Genius
      </a>
    ),
    // asMessage: true,
    trigger: "3.2.9"
  },
  {
    id: "3.3.1",
    message: "Would you like to learn about New York City?",
    trigger: "3.3.2"
  },
  {
    id: "3.3.2",
    options: [
      {
        value: "Yes",
        label: "Yes",
        trigger: "3.3.3"
      },
      {
        value: "No",
        label: "No",
        trigger: "3.3.4.1"
      }
    ]
  },
  {
    id: "3.3.3",
    message: "These are my favorite places to visit",
    trigger: "3.3.4"
  },
  {
    id: "3.3.4",
    component: (
      <a href="https://www.esbnyc.com/" target="_blank" rel="noopener noreferrer">
        The Empire State Building
      </a>
    ),
    // asMessage: true,
    trigger: "3.3.5"
  },
  {
    id: "3.3.5",
    component: (
      <a
        href="https://www.centralparknyc.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Central Park
      </a>
    ),
    // asMessage: true,
    trigger: "3.3.6"
  },
  {
    id: "3.3.6",
    component: (
      <a
        href="https://www.nps.gov/stli/index.htm"
        target="_blank"
        rel="noopener noreferrer"
      >
        The Statue of Liberty
      </a>
    ),
    // asMessage: true,
    trigger: "3.2.9"
  },
  {
    id: "3.3.4.1",
    message: "You can always grab a slice of pizza. This is my favorite spot",
    trigger: "3.3.4.2"
  },
  {
    id: "3.3.4.2",
    component: (
      <a
        href="http://www.joespizzanyc.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Joe&apos;s Pizza
      </a>
    ),
    // asMessage: true,
    trigger: "3.2.9"
  },
  {
    id: "5.1",
    options: [
      {
        value: "Games",
        label: "Games",
        trigger: "6.0"
      },
      {
        value: "Toys",
        label: "Toys",
        trigger: "7.0"
      },
      {
        value: "Nope",
        label: "Nope",
        trigger: "8.0"
      }
    ]
  },
  {
    id: "6.0",
    message: "What kind of games do you like to play?",
    trigger: "6.1"
  },
  {
    id: "6.1",
    options: [
      {
        value: "Card Games",
        label: "Card Games",
        trigger: "9.0"
      },
      {
        value: "Board Games",
        label: "Board Games",
        trigger: "10.0"
      },
      {
        value: "Party Games",
        label: "Party Games",
        trigger: "11.0"
      }
    ]
  },
  {
    id: "7.0",
    message: "What kind of toys are you looking for?",
    trigger: "7.1"
  },
  {
    id: "7.1",
    options: [
      {
        value: "Educational Toys",
        label: "Educational Toys",
        trigger: "12.0"
      },
      {
        value: "Puzzles",
        label: "Puzzles",
        trigger: "13.0"
      },
      {
        value: "Collectable Toys",
        label: "Collectable Toys",
        trigger: "14.0"
      }
    ]
  },

  {
    id: "8.0",
    message: "OK. Do you want to do some sight seeing?",
    trigger: "8.1"
  },
  {
    id: "8.1",
    options: [
      {
        value: "Yes!",
        label: "Yes!",
        trigger: "99"
      },
      {
        value: "No",
        label: "No",
        trigger: "100"
      }
    ]
  },
  {
    id: "100",
    message: "You can always grab a slice of pizza. This is my favorite spot.",
    trigger: "100.1"
  },
  {
    id: "100.1",
    component: (
      <a
        href="http://www.joespizzanyc.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Joe&apos;s Pizza
      </a>
    ),
    // asMessage: true,
    trigger: "99.3"
  },
  {
    id: "9.0",
    message: "Really?? I love card games too!",
    trigger: "20.0"
  },
  {
    id: "10.0",
    message: "What?! I love board games too!!",
    trigger: "20.1"
  },
  {
    id: "11.0",
    message: "No way!? I love party games too!!",
    trigger: "20.2"
  },
  {
    id: "12.0",
    message: "Let's go!! I love educational toys too!!",
    trigger: "21.0"
  },
  {
    id: "13.0",
    message: "Woo hoo! I love a good puzzle myself!",
    trigger: "21.1"
  },
  {
    id: "14.0",
    message: "I'm a huge fan of collectables!",
    trigger: "21.2"
  },
  {
    id: "20.0",
    message:
      "Visit these booths to see some great card games. Tell them Pickle sent you!",
    delay: 2000,
    trigger: "20.3"
  },
  {
    id: "20.1",
    message:
      "Visit these booths to see some great board games. Tell them Pickle sent you!",
    delay: 2000,
    trigger: "20.3"
  },
  {
    id: "20.2",
    message:
      "Visit these booths to see some great party games. Tell them Pickle sent you!",
    delay: 2000,
    trigger: "20.3"
  },
  {
    id: "20.3",
    component: (
      <div>
        Endless Games #165 -{" "}
        <a
          href="https://tfny2020.mapyourshow.com/8_0/floorplan/?hallID=B&selectedBooth=booth~165"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Here for Map
        </a>
      </div>
    ),
    // asMessage: true,
    trigger: "20.4"
  },
  {
    id: "20.4",
    component: (
      <div>
        Calliope Games #6706 -{" "}
        <a
          href="https://tfny2020.mapyourshow.com/8_0/floorplan/?hallID=A&selectedBooth=booth~6706"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Here for Map
        </a>
      </div>
    ),
    // asMessage: true,
    trigger: "20.5"
  },
  {
    id: "20.5",
    component: (
      <div>
        All Things Equal #130 -{" "}
        <a
          href="https://tfny2020.mapyourshow.com/8_0/floorplan/?hallID=B&selectedBooth=booth~130"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Here for Map
        </a>
      </div>
    ),
    trigger: "20.10"
  },
  {
    id: "20.10",
    message: "Have fun at Toy Fair!",
    trigger: "20.10.1"
  },
  {
    id: "20.10.1",
    message: "Oh, and tell them Pickle sent you!",
    trigger: "20.112",
    delay: 2000
  },
  {
    id: "20.112",
    message: "You can also checkout some fun toys and games online",
    trigger: "20.113"
  },
  {
    id: "20.113",
    message: "Do you like to shop on Amazon?",
    trigger: "20.114"
  },
  {
    id: "20.114",
    options: [
      {
        value: "Yes!",
        label: "Yes!",
        trigger: "20.115"
      },
      {
        value: "No",
        label: "No",
        trigger: "20.115.1"
      }
    ]
  },
  {
    id: "20.115",
    message: "Me too! I love to shop on Amazon.",
    trigger: "20.116"
  },
  {
    id: "20.116",
    message: "I even picked out my favorites, What's you favorite?",
    trigger: "20.117"
  },
  {
    id: "20.117",
    options: [
      {
        value: "Games",
        label: "Games",
        trigger: "20.118.0"
      },
      {
        value: "Toys",
        label: "Toys",
        trigger: "20.118.1"
      }
    ]
  },
  {
    id: "20.118.0",
    message: "Here are my best picks!",
    trigger: "20.118"
  },
  {
    id: "20.118",
    component: (
      <div>
        Endless Games -{" "}
        <a
          href="https://www.amazon.com/stores/node/183703390117"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Amazon
        </a>
      </div>
    ),
    trigger: "20.119"
  },
  {
    id: "20.119",
    component: (
      <div>
        All Things Equal -{" "}
        <a
          href="https://www.amazon.com/stores/node/25820020117"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Amazon
        </a>
      </div>
    ),
    trigger: "20.220"
  },
  {
    id: "20.220",
    component: (
      <div>
        Calliope Games -{" "}
        <a
          href="https://www.amazon.com/stores/node/207608020117"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Amazon
        </a>
      </div>
    ),
    trigger: "20.11"
  },
  {
    id: "20.115.1",
    message: "Really? I love to shop on Amazon",
    trigger: "20.115.2"
  },
  {
    id: "20.115.2",
    message: "Even if you don't shop. It's fun to look around.",
    trigger: "20.115.3"
  },
  {
    id: "20.115.3",
    message: "I was there looking at toys and games",
    trigger: "20.116"
  },
  {
    id: "20.118.1",
    message: "Here are my best picks!",
    trigger: "20.118.2"
  },

  {
    id: "20.118.2",
    component: (
      <div>
        Funko -{" "}
        <a
          href="https://www.amazon.com/stores/node/77135670117"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Amazon
        </a>
      </div>
    ),
    trigger: "20.118.3"
  },

  {
    id: "20.118.3",
    component: (
      <div>
        Playvisions -{" "}
        <a
          href="https://www.amazon.com/stores/node/25986640117"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Amazon
        </a>
      </div>
    ),
    trigger: "20.118.4"
  },
  {
    id: "20.118.4",
    component: (
      <div>
        Project Genius -{" "}
        <a
          href="https://www.amazon.com/stores/node/204421080117"
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Amazon
        </a>
      </div>
    ),
    trigger: "20.11"
  },
  {
    id: "20.11",
    message: "I Have enjoyed chatting with you",
    trigger: "20.12"
  },
  {
    id: "20.12",
    message:
      "Would you like to learn more about this chat experience for your business?",
    trigger: "20.13"
  },
  {
    id: "20.13",
    options: [
      {
        value: "Yes!",
        label: "Yes!",
        trigger: "20.14"
      },
      {
        value: "No",
        label: "No",
        trigger: "20.15"
      }
    ]
  },
  {
    id: "20.14",
    message: "Great! Give Pickle and the team a way to reach you.",
    trigger: "20.16"
  },
  {
    id: "20.15",
    message: "It was fun chatting and have a great day!",
    trigger: "22.1"
  },
  {
    id: "22.1",
    component: (
      <Form>
        <FormGroup>
          <Label for="exampleEmail">
            If you have any questions, you can enter them below
          </Label>
          <InputGroup>
            <Input />
            <InputGroupAddon addonType="append">
              <Button color="success">
                <FaAngleRight />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Form>
    ),
    end: true
  },
  {
    id: "20.16",
    component: (
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          onClickSubmit()
        }}
      >
        <FormGroup row>
          <Label for="exampleEmail" sm={2}>
            Email
          </Label>
          <Col sm={10}>
            <InputGroup>
              <Input type="text" id="inputID" />
              <InputGroupAddon addonType="append">
                <Button type="submit" color="success">
                  <FaAngleRight />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      </Form>
    ),
    end: true
  },
  {
    id: "21.0",
    message:
      "Visit these booths to see some great educational toys. Tell them Pickle sent you!",
    trigger: "21.3",
    delay: 2000
  },
  {
    id: "21.1",
    message:
      "Visit these booths to see some great puzzles. Tell them Pickle sent you!",
    trigger: "21.3",
    delay: 2000
  },
  {
    id: "21.2",
    message:
      "Visit these booths to see some great collectable toys. Tell them Pickle sent you!",
    trigger: "21.3",
    delay: 2000
  },
  {
    id: "21.3",
    component: (
      <div>
        {" "}
        Funko #54275 -{" "}
        <a
          href="https://tfny2020.mapyourshow.com/8_0/floorplan/?hallID=A&selectedBooth=booth~5307"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Here for Map
        </a>{" "}
      </div>
    ),
    // asMessage: true,
    trigger: "21.4"
  },
  {
    id: "21.4",
    component: (
      <div>
        {" "}
        Play Visions #419 -{" "}
        <a
          href="https://tfny2020.mapyourshow.com/8_0/floorplan/?hallID=B&selectedBooth=booth~419"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Here for Map
        </a>{" "}
      </div>
    ),
    // asMessage: true,
    trigger: "21.5"
  },
  {
    id: "21.5",
    component: (
      <div>
        {" "}
        Project Genius #129 -{" "}
        <a
          href="https://tfny2020.mapyourshow.com/8_0/floorplan/?hallID=B&selectedBooth=booth~129"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Here for Map
        </a>{" "}
      </div>
    ),
    // asMessage: true,
    trigger: "20.10"
  },
  {
    id: "99.0",
    component: (
      <a href="https://www.esbnyc.com/" target="_blank" rel="noopener noreferrer">
        The Empire State Building
      </a>
    ),
    // asMessage: true,
    trigger: "99.1"
  },
  {
    id: "99",
    message: "These are my favorite places to visit",
    // asMessage: true,
    trigger: "99.0"
  },
  {
    id: "99.1",
    component: (
      <a
        href="https://www.centralparknyc.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Central Park
      </a>
    ),
    // asMessage: true,
    trigger: "99.2"
  },
  {
    id: "99.2",
    component: (
      <div>
        <a
          href="https://www.nps.gov/stli/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          The State of Liberty
        </a>
      </div>
    ),
    // asMessage: true,
    trigger: "99.3"
  },
  {
    id: "99.3",
    message: "Have fun in New York City",
    trigger: "20.113",
    delay: 2000
  }
]

const theme = {
  background: "#f5f8fb",
  fontFamily: "Courier",
  headerBgColor: "#5DA33F",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#5DA33F",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a"
}

export { theme, steps }
