import React from "react"

const Times = () => {
  return (
    <div className="vh-100 vw-100 d-flex align-items-center justify-content-center">
      <h3>Times Route</h3>
    </div>
  )
}

export default Times
