import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FaRegArrowAltCircleLeft } from "react-icons/fa"

const HomeLink = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <>
      <button
        type="button"
        onClick={() => toggle()}
        className="footer-link d-flex flex-column align-items-center"
      >
        <FaRegArrowAltCircleLeft />
        Home
      </button>
      <Modal centered isOpen={modal} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>RETURN TO TOYFAIRBOT.COM</ModalHeader>
        <ModalBody>
          Would you like to go back to TOYFAIRBOT.com to start over? Your order
          settings will be reset.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle()}>
            No, Cancel
          </Button>
          <Button color="secondary" onClick={() => window.location.reload()}>
            Yes, Go Back
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default HomeLink
