import React from "react"
import { Container, Row } from "reactstrap"

import Bot from "./Bot"
import SideNav from "./SideNav"

const BotContainer = () => (
  <Container fluid className="overflow-hidden parent-container-bot">
    <Row className="container-bot">
      <SideNav />
      <Bot />
    </Row>
  </Container>
)

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
// eslint-disable-next-line prefer-const
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`)
window.addEventListener("resize", () => {
  // We execute the same script as before
  // eslint-disable-next-line prefer-const, no-shadow
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
})

export default BotContainer
