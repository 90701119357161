import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FaShoppingCart } from "react-icons/fa"

const CheckOutLink = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <>
      <button
        type="button"
        onClick={toggle}
        className="footer-link d-flex flex-column align-items-center align-content-between"
      >
        <FaShoppingCart />
        Checkout
      </button>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Checkout</ModalHeader>
        <ModalBody>
          Pickle is a chatbot designed by toy industry veterans and e-commerce
          experts. This technology can be used to increase your brand&apos;s online
          sales(Amazon or your website).
          <br />
          <br />
          The Chatbot can be linked to your storefront to directly checkout and
          process orders more efficiently
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Do Something
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CheckOutLink
