import React from "react"

import { HomeLink, SignInLink, CheckOutLink } from "./Links"

import "./index.css"

const Footer = () => (
  <div className="footer-container d-flex justify-content-center">
    <div className="w-100 d-flex justify-content-between footer-main position-relative">
      <HomeLink />
      <SignInLink />
      <CheckOutLink />
    </div>
  </div>
)

export default Footer
