import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FaUserAlt } from "react-icons/fa"

const SignInLink = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <>
      <button
        type="button"
        onClick={toggle}
        style={{ letterSpacing: "-1px" }}
        className="footer-link d-flex flex-column align-items-center"
      >
        <FaUserAlt />
        Info
      </button>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Learn More</ModalHeader>
        <ModalBody>
          Pickle is a chatbot designed by toy industry veterans and e-commerce
          experts. This technology can be used to increase your brand&apos;s online
          sales(Amazon or your website).
          <br />
          <br />
          Click Learn More below for more information
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            <a
              href="https://www.islandhawk.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
              Learn More
            </a>
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SignInLink
