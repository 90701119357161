import React from "react"
import ResizeDetector from "react-resize-detector"
import SimpleChatBot from "react-simple-chatbot"
import { Col } from "reactstrap"
import { steps } from "./constants"

const avatar = require("../../images/pickle-avatar.png")

const SimpleChatbot = () => (
  <ResizeDetector
    handleWidth
    render={({ width }) => {
      const contentStyle = {
        height: "100%",
        ...(width < 768 && {
          height: "100%",
          marginBottom: "133px"
        })
      }
      const footerStyle = {
        backgroundColor: "#e8e8e8",
        padding: ".75em",
        ...(width < 768 && {
          display: "flex",
          alignItems: "center",
          position: "fixed",
          bottom: 0,
          width: "calc(100% - 20px)"
        })
      }
      const inputStyle = {
        background: "#f6f6f6",
        borderRadius: ".75em",
        padding: ".5em",
        display: "inline-block",
        border: "1px solid #d0d0d0",
        color: "#666",
        height: "3em",
        fontSize: "1em"
      }
      const submitButtonStyle = {
        position: "relative",
        paddingRight: 0
      }

      const hideSubmitButton = width > 768
      return (
        <Col className="px-0 chatbot-col" sm="12" md="7">
          <SimpleChatBot
            avatarStyle={{ borderRadius: "50%" }}
            botAvatar={avatar}
            hideHeader
            submitButtonStyle={submitButtonStyle}
            hideSubmitButton={hideSubmitButton}
            contentStyle={contentStyle}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflowY: "hidden"
            }}
            footerStyle={footerStyle}
            inputStyle={inputStyle}
            steps={steps}
          />
        </Col>
      )
    }}
  />
)

export default SimpleChatbot
