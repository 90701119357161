import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"

import { Chatbot, Home, Times, GlobalChatbot } from "./components"

const App = () => (
  <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/(bot)?">
          <Chatbot />
        </Route>
        <Route exact path="/homepage">
          <Home />
        </Route>
        <Route exact path="/times">
          <Times />
        </Route>
      </Switch>
      <GlobalChatbot />
    </BrowserRouter>
  </>
)

export default App
