import React from "react"
import { ThemeProvider } from "styled-components"

import BotContainer from "./BotContainer"

import { theme } from "./constants"
import "./index.css"

const Chatbot = () => (
  <ThemeProvider theme={theme}>
    <BotContainer />
  </ThemeProvider>
)

export default Chatbot
