/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React from "react"
import { withRouter } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import PropTypes from "prop-types"
import GlobalSimpleChatbot from "react-simple-chatbot"
import moment from "moment"

import { steps, theme } from "../Chatbot/constants"

import "./index.css"

const GlobalChatbot = ({ location }) => {
  const given = moment("2020-02-22", "YYYY-MM-DD")
  const current = moment().startOf("day")

  // Difference in number of days
  const days = moment.duration(given.diff(current)).asDays()

  console.log("days ", days)
  if (location.pathname === "/times")
    return (
      <ThemeProvider theme={theme}>
        <GlobalSimpleChatbot
          opened
          floating
          hideSubmitButton
          steps={[
            {
              id: "0.5",
              message: `Today, Toy Fair is open from XAM to Y: PM. Ydfou have ${days} more days of Toy Fair left.`,
              trigger: "1.0"
            },
            ...steps
          ]}
        />
      </ThemeProvider>
    )
  return null
}

GlobalChatbot.defaultProps = {
  staticContext: undefined
}

GlobalChatbot.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.func
}

export default withRouter(GlobalChatbot)
